<template>
  <div class="animated fadeIn">
    <b-card :title="$t('message.title.clearMoneyReport')">
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-2 col-xl-2 my-1">
          {{ $t("message.year") }} :
          <br />
          <b-form-select v-model="timeSel" :options="language== 'th'? timeOptTH: timeOptEN" v-on:change="selectTime" />
        </div>

        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-4 my-1">
          {{ $t('message.machineId') }} : <br>
          <list-select
            :list="machineData"
            :selected-item="selectedId"
            placeholder="Select Machine ID"
            option-value="machineId"
            option-text="name"
            @select="onSelect"
          />
          <!-- <multi-list-select :list="machineData" option-value="machineId" option-text="name" :selected-items="selectedMachine" :placeholder="$t('message.all')" @select="onSelectMachine"></multi-list-select> -->
        </div>

        <div class="col-sm-6 col-md-6 col-lg-5 col-xl-3 my-1">
          {{ $t('message.date') }} :
          <br>
          <!-- <date-picker range type="date" v-model="date" lang="en" format="yyyy-MM-dd" width="220"></date-picker> -->
          <div class="table-responsive">
            <date-time-picker v-model="date" type="datetime" ></date-time-picker>
          </div>

        </div>
        <div class="col-sm-12 col-md-3 col-lg-2 col-xl-2 my-1">
          <br>
          <b-button class="col-lg-12" v-on:click="getClearMoneyReport()" variant="primary">
            <i class="fa fa-search"></i>&nbsp;{{ $t('message.search') }}
          </b-button>
        </div>
      </div>
      <br>

      <b-list-group-item class="table-responsive" v-if="rowData.length > 0">
        <table class="table2">
          <thead style="text-align-last: center">
            <tr>
              <th style="min-width: 80px;">{{ $t('message.machineId') }}</th>
              <th style="min-width: 160px; ">{{ $t('message.date') }}</th>
              <th>{{ $t('message.coin') }}</th>
              <th>{{ $t('message.banknote') }}</th>
              <th style="min-width: 60px;">{{ $t('message.balance') }}</th>
              <th>{{ $t('message.report') }}</th>
            </tr>
          </thead>
          <tbody style="text-align-last: center">
            <template v-for="(items, index) in rowData ">
              <tr>
                <td>{{items.machineId}}</td>
                <td style="text-align-last: center;">{{items.timeStamp | moment("YYYY-MM-DD HH:mm")}}</td>
                <td style="text-align-last: right"><span class="spanCustom" @click="showModal(items.value.data, items)">{{items.totalCoin}}</span></td>
                <td style="text-align-last: right"><span class="spanCustom" @click="showModal(items.value.data, items)">{{items.totalBill}}</span></td>
                <td style="text-align-last: right">{{webServices.addCommas(items.value.data.moneyInboxServer.total)}} </td>
                <td> <b-btn variant="success" @click="showModal(items.value.data, items)">{{ $t('message.modalTitle.summary') }}</b-btn></td>
              </tr>
            </template>
          </tbody>
        </table>
        <br>

        <b-pagination
          size="md"
          :total-rows="totalRows"
          v-model="pagination"
          :per-page="itemperPage"
          @input="pageChanged(pagination)"
        ></b-pagination>
        <!--        <uib-pagination :total-items=totalRows :items-per-page=itemperPage v-model="pagination" class="pagination-sm" @change="pageChanged(pagination.currentPage)"></uib-pagination>-->
      </b-list-group-item>
      <b-list-group-item v-else>
        <p style="text-align: center">{{ $t('message.transactionNotFound') }}</p>
      </b-list-group-item>
    </b-card>

    <b-modal ref="reportClearmoney" hide-header size="lg">
      <table class="table-modal">
        <thead>
          <tr>
            <th>{{ $t('message.productId') }}</th>
            <th>{{ $t('message.productName') }}</th>
            <th style="text-align: right">{{ $t('message.transactions') }}</th>
            <th style="text-align: right">{{ $t('message.sale') }}</th>
          </tr>
        </thead>
        <template v-for="item in reportData">
          <tr>
            <td>{{item.productId}}</td>
            <td v-if="item.product.name">{{item.product.name}}</td>
            <td style="text-align: right">{{webServices.addCommas(item.qty)}}</td>
            <td style="text-align: right">{{webServices.addCommas(item.price)}}</td>
          </tr>
        </template>
        <!--
        <tfoot>
          <tr>
            <th></th>
            <th>สรุป</th>
            <th style="text-align: right">{{webServices.addCommas(reportData.totalQty)}}</th>
            <th style="text-align: right">{{webServices.addCommas(reportData.totalPrice)}}</th>
          </tr>
        </tfoot>
        -->
      </table>
      <div slot="modal-footer" class="w-100">
        <download-excel
          class="btn btn-success float-right"
          :data="reportData"
          :fields="exportField"
          :name="reportFileName"
          :title="title"
        >
          Export
          <i class="icon-docs"></i>
        </download-excel>
        <b-btn variant="danger" class="float-left" @click="closeReportModal()">Close</b-btn>
      </div>
    </b-modal>

    <b-modal ref="detailModalMoney" hide-footer hide-header>
      <!-- value {{data_select}} -->
      <table class="table-modal" style="text-align: center">
        <thead style="text-align-last: center">
          <th>{{ $t('message.typeMoney') }}</th>
          <th>{{ $t('message.amount') }}</th>
          <th>{{ $t('message.total') }}</th>
        </thead>
        <tbody>
          <tr>
            <th>{{ $t('message.coin') }}</th>
            <td></td>
            <td></td>
          </tr>
          <template v-for="(value, key) in modalData.moneyInboxServer.coin">
            <tr>
              <td>{{key}} {{ $t('message.currency') }}</td>
              <td style="text-align-last: right">{{webServices.addCommas(value)}}</td>
              <td style="text-align-last: right">{{webServices.addCommas(value*key)}} {{ $t('message.currency') }}</td>
            </tr>
          </template>
          <tr>
            <th>{{ $t('message.banknote') }}</th>
            <td></td>
            <td></td>
          </tr>
          <template v-for="(value, key) in modalData.moneyInboxServer.bill">
            <tr>
              <td>{{key}} {{ $t('message.currency') }}</td>
              <td style="text-align-last: right">{{webServices.addCommas(value)}}</td>
              <td style="text-align-last: right">{{webServices.addCommas(value*key)}} {{ $t('message.currency') }}</td>
            </tr>
          </template>
        </tbody>
        <tfoot style="text-align-last: center">
          <td>
            <b-btn class="col-sm-12 col-md-8  my-2 mr-1" v-on:click="exportExcel(data_select)" variant="success" >
              <i class="fa fa-download"></i> {{ $t("message.export") }}
            </b-btn>
          </td>
          <th style="text-align-last: right">{{ $t('message.summary') }}</th>
          <th style="text-align-last: right">{{webServices.addCommas(modalData.moneyInboxServer.total)}} {{ $t('message.currency') }}</th>
        </tfoot>
      </table>
    </b-modal>
  </div>
</template>
<script>
import webServices from "../../script";
import moment from "moment";
import FileSaver from 'file-saver'
// import DatePicker from "../../custom_modules/vue2-datepicker";
import DateTimePicker from '../../components/DateTimePicker/Custom.vue'

// import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import {
  ListSelect,
  ModelListSelect,
  MultiListSelect
} from "../../custom_modules/search-select";

export default {
  components: {
    moment,
    ModelListSelect,
    ListSelect,
    MultiListSelect,
    // DatePicker,
    DateTimePicker
  },
  data() {
    return {
      api: {},
      timeSel: 0,
      timeOptTH: [
        { value: 0, text: '2022 ถึง ปีปัจจุบัน' },
        { value: 1, text: '<2022'}
      ],
      timeOptEN: [
        { value: 0, text: '2022 To This Year' },
        { value: 1, text: '<2022'}
      ],
      language: window.localStorage.getItem("language"),
      webServices: webServices,
      rowData: [],
      machineData: [],
      selectedId: {},
      totalRows: 1,
      pagination: 1,
      itemperPage: 20,
      searchData: {
        userId: window.localStorage.getItem("userId"),
        machineId: "",
        page: 1,
        rows: 20
      },
      data_select: [],
      modalData: {
        moneyInboxServer: {
          coin: "",
          bill: ""
        }
      },
      reportData: [],
      date: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
      exportField: {
        //  หมายเลขตู้: 'machineId',
        รหัสสินค้า: "productId",
        sku: "product.sku",
        ชื่อสินค้า: "product.name",
        จำนวน: "qty",
        ยอดขาย: "price"
      },
      reportFileName: "",
      title: [],
      fromdate: '',
      todate: '',
      selectedMachine: [],
    };
  },
  methods: {
    selectTime(value) {
      if (value > 0) {
        this.api.defaults.baseURL = '/old/' + this.axios.defaults.baseURL
      } else {
        this.api.defaults.baseURL = this.axios.defaults.baseURL
      }
    },

    showAlert_ChooseOneBranch() {
      if(window.localStorage.getItem("language") == 'th'){
        var title = "โปรดเลือกช่วงเวลาใหม่อีกครั้ง"
        var text = 'ไม่สามารถเลือกเดือนได้มากกว่า 12 เดือน'
        var confirmButton = "ปิด"
      }else{
        var title = "Please select a new time again"
        var text = "Cannot select more than 12 months."
        var confirmButton = "Close"
      }

      this.$swal({
        title: title,
        text: text,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: confirmButton
      })
    },

    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },

    searchFn(opt = { excel: false }, data_select) {
      console.log('xx', data_select)
      if (this.searchData.datetime !== '') {
        this.fromdate = moment(this.searchData.datetime[0]).format('YYYY-MM-DD HH:mm:ss')
        this.todate = moment(this.searchData.datetime[1]).endOf('date').format("YYYY-MM-DD HH:mm:ss");
      } else {
        this.fromdate = ''
        this.todate = ''
      }
    },

    exportExcel(data ) {
      // console.log('exportExcel', data)
      for (let i = 0; i < this.machineData.length; i++) {
        if( data.machineId === this.machineData[i].machineId){
          const machineName =  this.machineData[i].name
          this.searchData.name = machineName.substr(9).replaceAll('\\r\\n', '')
        }
      }

      // console.log('this.searchData', this.searchData)
      this.fromdate =  moment(data.value.lastevent).format("YYYY-MM-DD HH:mm:ss")
      this.todate =  moment(data.timeStamp).format("YYYY-MM-DD HH:mm:ss")

      const params = {
        ...this.searchData,
        from: this.fromdate,
        to: this.todate,
        machineId: data.machineId,
        timeStamp: moment(data.timeStamp).format("YYYY-MM-DD HH:mm:ss"),
      }
      this.$Progress.start()

      // if (Array.isArray(params.machineId)) {
      //   params.machineId = this.searchData.machineId.join(',')
      // }

      // if (Array.isArray(params.productId)) {
      //   params.productId = this.searchData.productId.join(',')
      // }

      // delete params.id
      // delete params.datetime

      this.axios({
        url: `/report/clearmoney/download`, // File URL Goes Here
        method: 'GET',
        params,
        responseType: 'blob'
      }).then(response => {
        FileSaver.saveAs(response.data, `Report_Checkout_Money_${data.machineId}_date_${moment(this.fromdate).format('YYYY-MM-DD')}_to_ ${moment(this.todate).format('YYYY-MM-DD')}.xlsx`)
        this.$Progress.finish()
      })
    },

    getClearMoneyReport() {
      this.$Progress.start();
      this.searchData.from = moment(this.date[0]).format("YYYY-MM-DD HH:mm:ss");
      this.searchData.to = moment(this.date[1]).format("YYYY-MM-DD HH:mm:ss");

      console.log('this.date',   this.date)
      console.log('this.searchData',   this.searchData)

      var localStorageUser =  JSON.parse(window.localStorage.getItem("users") )
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.searchData.userName = localStorageUser.userName
      this.searchData.userRole = localStorageUser.role
      this.searchData.userType = localStorageUser.type
      this.searchData.userConfiglanguage = localStorageUser.config.language
      this.searchData.datetimeRequest = datetimeRequest

      var dateStart = new Date(this.date[0])
      var dateEnd = new Date(this.date[1])

      console.log("monthDiff", this.monthDiff(dateStart, dateEnd))
      // if(this.monthDiff(dateStart, dateEnd) < 12){

        webServices
        .getClearInboxReport(this.searchData)
        .then(res => {
          this.$Progress.finish();
          this.rowData = res.data.rows;
          this.totalRows = res.data.count;
          // console.log("this.totalRows", this.totalRows)
          for (var i = 0; i < this.rowData.length; i++) {
            var totalCoin = 0;
            var totalBill = 0;
            Object.keys(
              this.rowData[i].value.data.moneyInboxServer.coin
            ).forEach(key => {
              var total = 0;
              total =
                key * this.rowData[i].value.data.moneyInboxServer.coin[key];
              totalCoin += total;
            });
            Object.keys(
              this.rowData[i].value.data.moneyInboxServer.bill
            ).forEach(key => {
              var total = 0;
              total =
                key * this.rowData[i].value.data.moneyInboxServer.bill[key];
              totalBill += total;
            });
            this.rowData[i].totalCoin = webServices.addCommas(totalCoin);
            this.rowData[i].totalBill = webServices.addCommas(totalBill);
          }
          this.selectedId = {};
          this.searchData.machineId = "";
        })
        .catch(err => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          console.log(err);
          console.log("error Clear money report");
          this.$toast.error({
            title: "ERROR",
            message: err.toString()
          });
        });
      // }else{
      //   console.log("เลือกเดือนได้สูงสุด 12 เดือน")
      //   this.showAlert_ChooseOneBranch() // เลือกเดือนได้สูงสุด 12 เดือน
      // }
    },

    closeReportModal() {
      this.$refs.reportClearmoney.hide();
    },

    onSelect(item) {
      this.selectedId = item;
      this.searchData.machineId = item.machineId;
    },

    onSelectMachine(items, lastSelectItem) {
      // console.log(items)
      this.selectedMachine = items
      this.searchData.machineId = this.selectedMachine.map(data => data.machineId)

    },

    showModal(data, items) {
      // console.log('items', items)
      this.modalData = items.value.data;
      this.data_select = items
      this.$refs.detailModalMoney.show();
    },

    pageChanged(PageNum) {
      this.searchData.page = PageNum;
      this.getClearMoneyReport();
    },

    getMachine() {
      webServices
        .getMachineList(window.localStorage.getItem("userId"))
        .then(res => {
          this.machineData = res.data;
          // console.log(this.machineData)
        })
        .catch(err => {
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          });
          console.log("error @machine");
          console.log(err);
        });
    },


  },
  mounted() {
    this.getClearMoneyReport();
    this.getMachine();
  }
};
</script>
<style>
.spanCustom {
  cursor: pointer;
  color: #20a8d8;
  border-color: #20a8d8;
  text-decoration: underline;
}
.spanCustom:hover {
  text-decoration: none;
  text-shadow: 1px 1px 1px #555;
}
</style>